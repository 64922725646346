// components/Divider.tsx

import React from 'react';

const Divider: React.FC = () => {
  return (
    <div className="my-8">
      <hr className="border-gold opacity-50" />
    </div>
  );
};

export default Divider;