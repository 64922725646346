// stores/RSVPStore.ts

import { makeAutoObservable } from 'mobx';
import API from '../api/client';

interface Guest {
  name: string;
  meal_choice: string;
}

class RSVPStore {
  mainGuestName = '';
  email = '';
  numberOfGuests = 1;
  attending = true;
  guests: Guest[] = [{ name: '', meal_choice: 'meat' }];

  constructor() {
    makeAutoObservable(this);
  }

  setMainGuestName(value: string) {
    this.mainGuestName = value;
  }

  setEmail(value: string) {
    this.email = value;
  }

  setNumberOfGuests(value: number) {
    this.numberOfGuests = value;
    // Adjust the guests array to match the number of guests
    while (this.guests.length < value) {
      this.guests.push({ name: '', meal_choice: 'meat' });
    }
    while (this.guests.length > value) {
      this.guests.pop();
    }
  }

  setAttending(value: boolean) {
    this.attending = value;
    if (!value) {
      this.guests = [];
    } else if (this.guests.length === 0) {
      this.setNumberOfGuests(this.numberOfGuests);
    }
  }

  setGuestName(index: number, value: string) {
    this.guests[index].name = value;
  }

  setMealChoice(index: number, value: string) {
    this.guests[index].meal_choice = value;
  }

  async submitForm() {
    try {
      const response = await API.post('/api/rsvps/', {
        main_guest_name: this.mainGuestName,
        email: this.email,
        number_of_guests: this.numberOfGuests,
        attending: this.attending,
        guests: this.guests,
      });
      return response.data;
    } catch (error) {
      // Handle error appropriately
      throw error;
    }
  }
}

export const rsvpStore = new RSVPStore();