// components/CountdownTimer.tsx

import React, { useState, useEffect } from 'react';

const CountdownTimer: React.FC = () => {
  const calculateTimeLeft = () => {
    const difference = +new Date('2024-11-30T13:30:00') - +new Date();
    let timeLeft = {} as any;

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000); // Update every second

    return () => clearTimeout(timer);
  });

  const timerComponents: JSX.Element[] = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval as keyof typeof timeLeft]) {
      return;
    }

    timerComponents.push(
      <span key={interval} className="mx-2">
        {timeLeft[interval as keyof typeof timeLeft]} {interval}{' '}
      </span>
    );
  });

  return (
    <div className="text-center mt-6">
      {timerComponents.length ? (
        <p className="text-xl font-serif">
          {timerComponents} until our wedding!
        </p>
      ) : (
        <p className="text-xl font-serif">Our wedding day has arrived!</p>
      )}
    </div>
  );
};

export default CountdownTimer;