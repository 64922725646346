// components/HeroSection.tsx

import React from 'react';
import { ChevronDoubleDownIcon } from '@heroicons/react/24/solid';
import CountdownTimer from './CountdownTimer';

interface HeroSectionProps {
  openModal: () => void;
}

const HeroSection: React.FC<HeroSectionProps> = ({ openModal }) => {
  const scrollToNextSection = () => {
    const nextSection = document.getElementById('next-section');
    nextSection?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <section
      className="relative flex flex-col items-center text-center p-8 bg-cover bg-top bg-no-repeat text-white font-cursive min-h-screen"
      style={{
        backgroundImage:
          'url(https://globe-bucket.s3.amazonaws.com/Best_Engagement_Photo.webp)',
      }}
    >
      {/* Content Container */}
      <div className="mt-8 sm:mt-12">
        <h1 className="text-4xl sm:text-5xl mb-4">You're Invited!</h1>
        <p className="text-lg sm:text-xl mb-6 max-w-md mx-auto">
          We are thrilled to invite you to our wedding celebration on November 30, 2024.
        </p>
        <button
          onClick={openModal}
          className="bg-gold text-white px-6 py-3 rounded-full text-lg hover:bg-yellow-600 transition duration-300 ease-in-out font-serif"
        >
          RSVP Now
        </button>
      </div>
      <CountdownTimer /> {/* Add the countdown timer here */}


      {/* Gradient Overlay at the Bottom */}
        <div className="absolute bottom-0 w-full h-8 bg-gradient-to-t from-white to-transparent"></div>

      {/* Animated Scroll Down Arrow */}
      <div
        className="absolute bottom-8 animate-bounce cursor-pointer"
        onClick={scrollToNextSection}
      >
        <ChevronDoubleDownIcon className="w-10 h-10 text-white" />
      </div>
    </section>
  );
};

export default HeroSection;