import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Logo from './Logo';
import NavLinks from './HeaderNavLinks';
import MobileMenu, { MobileMenuButton } from './MobileMenu';

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const location = useLocation();
  const { pathname } = location;

  return pathname !== '/wedding' ? (
    <>
      <header className="fixed top-0 left-0 w-full bg-[#1E3146] text-white flex items-center justify-between px-6 md:px-24 py-4 md:py-6 z-50 shadow-lg">
        <Logo />
        <div className="hidden lg:flex items-center justify-end flex-grow">
          <NavLinks />
        </div>
        <MobileMenuButton toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
      </header>
      <MobileMenu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} toggleModal={toggleModal} />
    </>
  ) : null;
};

export default Header;