// pages/Home.tsx

import React, { useState } from 'react';
import HeroSection from './HeroSection';
import WeddingDetails from './WeddingDetails';
import ReceptionTimeline from './ReceptionTimeline';
import RSVPModal from './RSVPModal';
import Divider from './Divider';
import Registry from './Registry';

const Home: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="bg-white min-h-screen">
        <HeroSection openModal={openModal} />
        <Divider />
        <WeddingDetails />
        <Divider />
        <ReceptionTimeline />
        <Divider />
        <Registry />
        <RSVPModal isOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
};

export default Home;