// components/Registry.tsx

import React from 'react';

const Registry: React.FC = () => {
  return (
    <section className="text-center p-8 bg-white text-gold font-serif">
      <h2 className="text-3xl font-cursive mb-6">Our Wedding Registry</h2>
      <a
        href="https://www.amazon.com/wedding/registry/2E2L2UZDVW5N"
        target="_blank"
        rel="noopener noreferrer"
        className="inline-block bg-gold text-white px-6 py-3 rounded-full text-lg hover:bg-yellow-600 transition duration-300 ease-in-out"
      >
        View Our Registry
      </a>
    </section>
  );
};

export default Registry;