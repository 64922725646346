// components/WeddingDetails.tsx

import React from 'react';

const WeddingDetails: React.FC = () => {
  return (
    <section className="text-center p-8 font-cursive text-gold animate-fadeIn">
      <h2 className="text-5xl mb-6">Kevin & Vanessa</h2>
      <div className="mb-12">
        <h2 className="text-3xl mb-2">Wedding Ceremony</h2>
        <p className="text-xl">
          Compass Church Marina<br />
          3131 Crescent Ave, Marina, CA 93933<br />
          1:30 P.M. on November 30, 2024
        </p>
      </div>
      <div>
        <h2 className="text-3xl mb-2">Reception</h2>
        <p className="text-xl">
          Barlocker Rustling Oaks Ranch<br />
          25252 Limekiln Road, Salinas, CA 93901<br />
          4:00 P.M.
        </p>
      </div>
    </section>
  );
};

export default WeddingDetails;