// components/RSVPModal.tsx

import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { observer } from 'mobx-react-lite';
import { rsvpStore } from '../../stores/RSVPStore';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { toast } from 'react-toastify';

interface RSVPModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const RSVPModal: React.FC<RSVPModalProps> = observer(({ isOpen, closeModal }) => {
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await rsvpStore.submitForm();
      // Display success toast
      toast.success('Your RSVP has been submitted successfully!');
      closeModal();
    } catch (error) {
      // Display error toast
      toast.error('There was an error submitting your RSVP. Please try again.');
      console.error('Submission failed:', error);
    }
  };


  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto font-sans"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center bg-black bg-opacity-50">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
              <div className="flex justify-between items-center">
                <Dialog.Title as="h3" className="text-2xl font-cursive text-gold">
                  RSVP
                </Dialog.Title>
                <button onClick={closeModal} className="text-gray-500 hover:text-gray-700">
                  <XMarkIcon className="w-6 h-6" />
                </button>
              </div>
              <form onSubmit={handleSubmit} className="mt-4 space-y-4">
                {/* Form fields */}
                {/* Main Guest Name Field */}
                <div>
                  <label className="block text-sm font-medium">Your Name</label>
                  <input
                    type="text"
                    value={rsvpStore.mainGuestName}
                    onChange={(e) => rsvpStore.setMainGuestName(e.target.value)}
                    className="mt-1 block w-full border rounded-md p-2"
                    required
                  />
                </div>

                {/* Email Field */}
                <div>
                  <label className="block text-sm font-medium">Email</label>
                  <input
                    type="email"
                    value={rsvpStore.email}
                    onChange={(e) => rsvpStore.setEmail(e.target.value)}
                    className="mt-1 block w-full border rounded-md p-2"
                    required
                  />
                </div>

                {/* Attending Checkbox */}
                <div>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      checked={rsvpStore.attending}
                      onChange={(e) => rsvpStore.setAttending(e.target.checked)}
                      className="form-checkbox"
                    />
                    <span className="ml-2">Attending</span>
                  </label>
                </div>

                {rsvpStore.attending && (
                  <>
                    {/* Number of Guests */}
                    <div>
                      <label className="block text-sm font-medium">Number of Guests</label>
                      <input
                        type="number"
                        value={rsvpStore.numberOfGuests}
                        onChange={(e) =>
                          rsvpStore.setNumberOfGuests(Number(e.target.value))
                        }
                        className="mt-1 block w-full border rounded-md p-2"
                        min={1}
                        required
                      />
                    </div>

                    {/* Guest Details */}
                    {rsvpStore.guests.map((guest, index) => (
                      <div key={index} className="border p-2 rounded-md">
                        <h3 className="text-lg font-medium">Guest {index + 1}</h3>
                        <div>
                          <label className="block text-sm font-medium">Name</label>
                          <input
                            type="text"
                            value={guest.name}
                            onChange={(e) =>
                              rsvpStore.setGuestName(index, e.target.value)
                            }
                            className="mt-1 block w-full border rounded-md p-2"
                            required
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium">Meal Choice</label>
                          <select
                            value={guest.meal_choice}
                            onChange={(e) =>
                              rsvpStore.setMealChoice(index, e.target.value)
                            }
                            className="mt-1 block w-full border rounded-md p-2"
                          >
                            <option value="meat">Meat</option>
                            <option value="seafood">Seafood</option>
                            <option value="vegetarian">Vegetarian</option>
                          </select>
                        </div>
                      </div>
                    ))}
                  </>
                )}

                {/* Submit Button */}
                <div>
                  <button
                    type="submit"
                    className="w-full py-2 px-4 bg-gold text-white rounded-md hover:bg-yellow-600 transition duration-300 ease-in-out text-lg"
                  >
                    Submit RSVP
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
});

export default RSVPModal;