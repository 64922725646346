
import React from 'react';

const ReceptionTimeline: React.FC = () => {
  return (
    <section className="text-center p-8 font-cursive text-gold">
      <h2 className="text-4xl mb-6">Reception Timeline</h2>
      <ul className="text-xl space-y-4">
        <li>
          <strong>4:00 PM</strong> - Guests Arrival & Cocktail Hour
        </li>
        <li>
          <strong>5:00 PM</strong> - Dinner Served
        </li>
        <li>
          <strong>5:45 PM</strong> - Speeches & Toasts
        </li>
        <li>
          <strong>6:15 PM</strong> - First Dance
        </li>
        <li>
          <strong>6:30 PM</strong> - Cake Cutting
        </li>
        <li>
          <strong>6:45 PM</strong> - Bouquet & Garter Toss
        </li>
        <li>
          <strong>7:00 PM</strong> - Dancing & Celebration
        </li>
        <li>
          <strong>7:45 PM</strong> - Farewell
        </li>
      </ul>
    </section>
  );
};

export default ReceptionTimeline;